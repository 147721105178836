import Flex from 'components/base/Flex';
import ArtworkCardSkeleton from 'components/cards/artwork/ArtworkCardSkeleton';
import ArtworkCardAlgoliaMarket from 'components/cards/artwork/subcomponents/ArtworkCardAlgoliaMarket';
import ArtworkCardMedia from 'components/cards/artwork/subcomponents/ArtworkCardMedia';
import { PopoverMenuOption } from 'components/popover/types';

import {
  buildArtworkPath,
  isUnsupportedArtworkAsset,
} from 'utils/artwork/artwork';
import {
  buildArtworkAssetUrl,
  buildPosterUrl,
  FALLBACK_IMAGE_IMGIX_BLUR,
} from 'utils/assets';
import { buildCollectionPathWithMediaFilter } from 'utils/collections';
import { isNumber } from 'utils/helpers';
import { isFlaggedForModeration } from 'utils/moderation';

import { AlgoliaArtwork } from 'types/Algolia';
import { AssetMimeType } from 'types/Assets';
import { MarketAvailability } from 'types/MarketAvailability';
import { NftSortOrder } from 'types/Nft';
import { FallbackAssetFragment } from 'types/artwork/artwork';

import ModeratedBanner from '../shared/ModeratedBanner';

import ArtworkCardCreator from './subcomponents/ArtworkCardCreator';
import ArtworkCardHeader from './subcomponents/ArtworkCardHeader';
import ArtworkCardMenu from './subcomponents/ArtworkCardMenu';
import ArtworkCardRoot from './subcomponents/ArtworkCardRoot';
import ArtworkCardStackBadge from './subcomponents/ArtworkCardStackBadge';
import ArtworkHiddenLink from './subcomponents/ArtworkHiddenLink';
import { ArtworkCardProps } from './types';

export interface ArtworkCardAlgoliaProps extends ArtworkCardProps {
  artwork: AlgoliaArtwork & { distinctAssetKey?: string };
  marketAvailability: MarketAvailability | null;
  // TODO: get rid of undefined after '/[tag]' is removed
  sortOrder: NftSortOrder | undefined;
  fallbackAsset: FallbackAssetFragment;
  menuOptions?: PopoverMenuOption[];
}

export default function ArtworkCardAlgolia(props: ArtworkCardAlgoliaProps) {
  const {
    artwork,
    marketAvailability,
    sortOrder = 'DEFAULT',
    fallbackAsset,
    menuOptions,
    itemCount = 1,
    variant = 'nft',
  } = props;

  const { creator, auction } = artwork;

  if (!artwork) {
    return <ArtworkCardSkeleton />;
  }

  const artworkPath =
    variant === 'nft'
      ? buildArtworkPath({ artwork, user: creator })
      : artwork.distinctAssetKey
        ? buildCollectionPathWithMediaFilter(
            artwork.collection,
            artwork.distinctAssetKey
          )
        : '';

  const blur = isUnsupportedArtworkAsset(artwork)
    ? FALLBACK_IMAGE_IMGIX_BLUR
    : undefined;

  const gifImgixOptions = {
    q: 50,
    quality: 'preview',
    auto: 'format,compress',
    blur,
  } as const;

  const imgixOptions =
    artwork.mimeType === 'image/gif'
      ? gifImgixOptions
      : ({
          h: 640,
          q: 50,
          quality: 'preview',
          auto: 'format,compress',
          blur,
        } as const);

  const optimizedAssetUrl = buildArtworkAssetUrl(imgixOptions, artwork);

  const posterUrl = buildPosterUrl(artwork);
  const isAuctionMode =
    variant === 'nft'
      ? artwork?.availability === 'LIVE_AUCTION' || auction?.status === 'ENDED'
      : false;

  const moderationStatus = artwork?.moderationStatus;
  const isModerated = isFlaggedForModeration(moderationStatus);
  const showMenu = menuOptions && menuOptions.some((option) => option.enabled);

  return (
    <ArtworkCardRoot isAuctionMode={isAuctionMode} itemCount={itemCount}>
      {isModerated && <ModeratedBanner status={moderationStatus} />}
      <ArtworkCardMedia
        href={artworkPath}
        assetStatus={artwork.assetStatus}
        optimizedAssetUrl={optimizedAssetUrl}
        posterUrl={posterUrl}
        name={artwork.name}
        collection={artwork.collection}
        creator={creator}
        tokenId={artwork.tokenId}
        fallbackAsset={fallbackAsset}
        variant={variant}
        itemCount={itemCount}
        mintedAt={artwork.mintedAt}
        mimeType={artwork.mimeType as AssetMimeType}
      />

      <ArtworkCardHeader>
        <Flex
          css={{
            justifyContent: 'space-between',
            position: 'relative',
          }}
        >
          <ArtworkCardCreator
            artwork={artwork}
            color={isAuctionMode ? 'dark' : 'light'}
          />

          {showMenu && (
            <ArtworkCardMenu
              isAuctionMode={isAuctionMode}
              options={menuOptions}
            />
          )}
        </Flex>
        {variant === 'nft' ? (
          <ArtworkCardAlgoliaMarket
            auction={auction}
            artwork={artwork}
            owner={artwork.owner}
            marketAvailability={marketAvailability}
            sortOrder={sortOrder}
          />
        ) : (
          <Flex
            css={{
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            <ArtworkCardStackBadge itemCount={itemCount} />
          </Flex>
        )}
        {isNumber(artwork.tokenId) && (
          <ArtworkHiddenLink name={artwork.name} href={artworkPath} />
        )}
      </ArtworkCardHeader>
    </ArtworkCardRoot>
  );
}
